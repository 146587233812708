import AGENCY_ROUTES from '@agency/routes/routes.constant';
import { $http } from '@core/http-client';
import LANDLORD_ROUTES from '@landlord/routes/routes.constant';
import { BulkActionCompletedToast } from '@shared/components/bulk-action-completed-toast';
import { BulkActionProgressToast } from '@shared/components/bulk-action-progress-toast';
import { PusherLegacyNotification } from '@shared/components/pusher-legacy-notification';
import { t } from '@shared/i18n';
import { ConfigCore } from '@shared/models/config';
import {
  PusherKey,
  PusherKeyEnvDisposal,
  PusherKeyEnvObject,
  PusherKeyEnvUser,
  PusherKeySociety,
  PusherKeyUser,
  PusherNotificationKey,
  PusherNotificationPayload,
  PusherPayload,
} from '@shared/types/common/events';
import { getBaseUrl } from '@shared/utils/base-url';

import { AppId } from './ConfigContext';
import { ToastItem } from './PusherContext';

export const getEventToast = (
  event: PusherKey,
  suppliedPayload: ValueOf<PusherPayload>,
  appId: AppId,
  config: ConfigCore,
  onClose: (toastKey: string) => void,
  onClickLink: (link: string) => void
): ToastItem | undefined => {
  // console.log(`Pusher event: ${event}`, suppliedPayload);
  const time = Date.now();

  switch (event) {
    case PusherKeySociety.AcquisitionPublishedToSociety: {
      // const payload = suppliedPayload as PusherEventPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   dismissTime: 10000,
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       eventString="added new requirement"
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = 'society-acquisitions.view';
      //         // data.link_params = { advert_id: payload.id };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PusherKeyUser.BulkActionCompleted: {
      const payload = suppliedPayload as PusherPayload[typeof event];
      const key = `${event}_${payload.job_id}`;

      return {
        dismissTime: 20000,
        key: key,
        replaceKey: `${PusherKeyUser.BulkActionProgress}_${payload.job_id}`,
        time: time,
        content: (
          <BulkActionCompletedToast key={key} {...payload} onClose={() => onClose(key)} onClickLink={onClickLink} />
        ),
      };
    }

    case PusherKeyUser.BulkActionProgress: {
      const payload = suppliedPayload as PusherPayload[typeof event];
      const key = `${event}_${payload.job_id}`;
      return {
        dismissTime: 10000,
        key: key,
        time: time,
        content: <BulkActionProgressToast key={key} {...payload} onClose={() => onClose(key)} />,
      };
    }

    case PusherKeySociety.LettingPublishedToSociety: {
      // const payload = suppliedPayload as PusherEventPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   dismissTime: 10000,
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       eventString="added new letting"
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = 'society-lettings.view';
      //         // data.link_params = { advert_id: payload.id };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PusherKeyEnvUser.ScheduleMatchAgentMessageCreated: {
      const payload = suppliedPayload as PusherPayload[typeof event];
      const key = `${event}_${payload.disposal_id}_${payload.requirement_id}_${payload.message.id}`;

      if (payload.message.user_id == config.user.id) {
        return undefined;
      }

      return {
        dismissTime: 10000,
        key: key,
        time: time,
        content: (
          <PusherLegacyNotification
            key={key}
            headline={payload.message.body}
            eventString={t('you_have_a_new_message')}
            user={payload.message.user}
            onClose={() => onClose(key)}
            onClick={() => {
              // console.log('TODO click');

              if (payload.disposal_organisation_id == config.user.organisation?.id) {
                // TODO: Review this
                switch (appId) {
                  case AppId.Agency: {
                    const managePath = AGENCY_ROUTES.organisation.disposals.manage.root(payload.disposal_id.toString());
                    onClickLink(`${managePath}${AGENCY_ROUTES.organisation.disposals.manage.reporting}`);
                    break;
                  }

                  case AppId.Landlord: {
                    const managePath = LANDLORD_ROUTES.organisation.disposals.manage.root(
                      payload.disposal_id.toString()
                    );
                    onClickLink(`${managePath}${LANDLORD_ROUTES.organisation.disposals.manage.reporting}`);
                    break;
                  }
                }
                // notification.link = DISPOSALS.VIEWS.INTEREST_SCHEDULE_VIEW;
                // notification.link_params = {
                //     match_id: payload.message.object_id,
                //     id: payload.disposal_id,
                //     match_tab: 'agent_messages'
                // };
              } else if (payload.requirement_organisation_id == config.user.organisation?.id) {
                // TODO: Review this
                const path = `${getBaseUrl()}#/requirements/${payload.requirement_id}/manage/shortlist`;
                onClickLink(path);

                // notification.link = REQUIREMENTS.VIEWS.SHORTLIST_VIEW;
                // notification.link_params = {
                //     match_id: payload.message.object_id,
                //     requirement_id: payload.requirement_id,
                //     match_tab: 'agent_messages',
                //     match_message_tab: payload.disposal_organisation_id // organisation of the thread we're talking about
                // };
              }
            }}
          />
        ),
      };
    }

    case PusherKeyEnvDisposal.DisposalCountsUpdated:
    case PusherKeyEnvDisposal.InterestCountsUpdated:
    case PusherKeyEnvDisposal.InterestMovedToSchedule:
    case PusherKeyEnvObject.ObjectAdded:
    case PusherKeyEnvObject.ObjectDeleted:
    case PusherKeyEnvObject.ObjectUpdated:
    case PusherKeyEnvObject.ObjectsUpdated:
      return undefined;
  }
};

export const getNotificationToast = (
  event: PusherNotificationKey,
  suppliedPayload: ValueOf<PusherNotificationPayload>,
  appId: AppId,
  onClose: (toastKey: string) => void,
  onClickLink: (link: string) => void
): ToastItem | undefined => {
  // console.log(`Pusher notification: ${event}`, suppliedPayload);
  // const time = Date.now();

  switch (event) {
    case PusherNotificationKey.UserOrTeamMentionedOnInterestScheduleComment: {
      // const payload = suppliedPayload as PusherNotificationPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   dismissTime: 10000,
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       eventString={t('you_have_been_mentioned')}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = DISPOSALS.VIEWS.INTEREST_SCHEDULE_VIEW;
      //         // data.link_params = { id: payload.disposal_id, match_id: payload.match_id };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PusherNotificationKey.UserOrTeamMentionedOnRequirementComment: {
      // const payload = suppliedPayload as PusherNotificationPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   dismissTime: 10000,
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       eventString={t('you_have_been_mentioned')}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = REQUIREMENTS.VIEWS.VIEW;
      //         // data.link_params = { requirementId: payload.acquisition_id, requirement_tab: 'internal_comments' };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PusherNotificationKey.UserOrTeamMentionedOnShortlistComment: {
      // const payload = suppliedPayload as PusherNotificationPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   dismissTime: 10000,
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       eventString={t('you_have_been_mentioned')}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = REQUIREMENTS.VIEWS.SHORTLIST_VIEW;
      //         // data.link_params = { requirement_id: payload.requirement_id, match_id: payload.match_id };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }

    case PusherNotificationKey.UserOrTeamMentionedOnSocietyAcquisitionComment: {
      // const payload = suppliedPayload as PusherNotificationPayload[typeof event];
      // const key = `${event}_${payload.id}`;
      // return {
      //   dismissTime: 10000,
      //   key: key,
      //   time: time,
      //   content: (
      //     <PusherLegacyNotification
      //       key={key}
      //       headline={payload.headline}
      //       subline={payload.subline}
      //       eventString={t('you_have_been_mentioned')}
      //       user={payload.user}
      //       onClose={() => onClose(key)}
      //       onClick={() => {
      //         // console.log('TODO click');
      //         // data.link = REQUIREMENTS.VIEWS.VIEW_SOCIETY_ACQUISITION;
      //         // data.link_params = { advert_id: payload.society_acquisition_id, acquisition_tab: 'internal_comments' };
      //       }}
      //     />
      //   ),
      // };
      // TODO: Uncomment above when we have dev resource available
      return undefined;
    }
  }
};

export const addOrUpdateToast = (toast: ToastItem, _prev: ToastItem[]) => {
  let newToasts: ToastItem[] = [];
  if (toast.replaceKey) {
    // Remove a related existing toast
    // console.log('addOrUpdateToast. replace toast w/ key:', toast.replaceKey);
    newToasts = _prev.filter((item) => item.key !== toast.replaceKey);
  } else {
    newToasts = [..._prev];
  }

  const index = newToasts.findIndex((item) => item.key === toast.key);

  if (index !== -1) {
    // Update existing toast
    // console.log('addOrUpdateToast. update existing toast:', toast.key, toast);
    newToasts[index] = toast;
  } else {
    // Add new toast
    // console.log('addOrUpdateToast. add new toast:', toast.key, toast);
    newToasts.push(toast);
  }

  return newToasts;
};

export const echoAuthorizer = (channel: any) => {
  return {
    authorize: (socketId: any, callback: any) => {
      $http
        .post('/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name,
        })
        .then((response) => {
          callback(false, response.data);
        })
        .catch((error) => {
          callback(true, error);
        });
    },
  };
};
