import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';

import { t } from '@core/i18n';

export interface UnsavedChangesContextValue {
  hasChanges: boolean;
  setHasChanges: UseStateSetter<boolean>;
  message: string;
  setMessage: UseStateSetter<string>;
}

export const UnsavedChangesContext = createContext<UnsavedChangesContextValue>({} as UnsavedChangesContextValue);

// Used for glueing route changes with unsaved changes prompt
export const useUnsavedChanges = (message?: string) => {
  const context = useContext(UnsavedChangesContext);

  useEffect(() => {
    context.setMessage(message || t('you_have_unsaved_changes_do_you_want_to_continue_without_saving_question'));
  }, [message]);

  return useMemo(
    () => ({
      changesPrompt: <Prompt when={context.hasChanges} message={context.message} />,
      hasChanges: context.hasChanges,
      setHasChanges: context.setHasChanges,
    }),
    [context]
  );
};

export const UnsavedChangesProvider: FC = ({ children }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [message, setMessage] = useState('');

  // Leaving the page via external link (Angular pages)
  useEffect(() => {
    if (!hasChanges) {
      return;
    }

    const unloadAbort = new AbortController();

    window.addEventListener(
      'beforeunload',
      (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
      },
      { signal: unloadAbort.signal }
    );

    return () => unloadAbort.abort();
  }, [hasChanges]);

  const contextValue = useMemo(() => ({ hasChanges, setHasChanges, message, setMessage }), [hasChanges, message]);

  return <UnsavedChangesContext.Provider value={contextValue}>{children}</UnsavedChangesContext.Provider>;
};
