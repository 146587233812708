import { createContext, FC, useContext, useMemo } from 'react';

import { t } from '@core/i18n';
import { RequirementStatus } from '@shared/apis/requirementsApi.types';
import { useConfigAll } from '@shared/contexts/ConfigContext';
import { ConfigCoreConstants } from '@shared/models/config';
import { IUser } from '@shared/models/IUser';
import { ViewingOutcome } from '@shared/models/viewing/viewings';
import {
  filterOptions,
  getGroupedOptions,
  idArrayToNumberOptions,
  OptionsItem,
  optionsToFlatGroupOptions,
  sourceOptionsSelectOptions,
  stringObjectToNumberOptions,
  stringObjectToOptions,
} from '@shared/utils/options';

export const getCoreOptionsValue = (constants: ConfigCoreConstants, user: IUser) => {
  // All (used by others below)
  const allSectorOptions = stringObjectToOptions(constants.sectors);
  const allSourceOptions = sourceOptionsSelectOptions(constants.localisation.sourceOptions);

  // Options (used by others below)
  const acquisitionFittedSpaceOptions = stringObjectToOptions(constants.acquisitionFittedSpaceOptions);
  const buildingTypeOptions = stringObjectToOptions(constants.buildingTypes);
  const classOfUseOptions = stringObjectToOptions(constants.classOfUse);
  const investmentProfileOptions = stringObjectToOptions(constants.investmentProfileOptions);
  const investmentStrategyOptions = stringObjectToOptions(constants.investmentStrategyOptions);
  const investmentStructureOptions = stringObjectToOptions(constants.investmentStructureOptions);
  const leaseTypeOptions = stringObjectToOptions(constants.leaseTypes);
  const preferredSourceOptions = allSourceOptions.filter((option) => constants.preferredSources.includes(option.id));
  const requirementReasonOptions = stringObjectToOptions(constants.requirementReasonOptions);
  const requirementStatusOptions = idArrayToNumberOptions<RequirementStatus>(constants.requirementStatusOptions);
  const sectorOptions = filterOptions(allSectorOptions, user.organisation?.settings.sectors_visible, true);
  const preferredSectorOptions = filterOptions(allSectorOptions, constants.preferredSectors, true);

  // Static / processed options
  const acquisitionAdvertTypeOptions: OptionsItem<number>[] = constants.acquisitionAdvertTypeOptions.map(
    (label, idx) => ({ id: idx, label })
  );
  const booleanOptions: OptionsItem<NumberBoolean>[] = [
    { id: 1, label: t('yes') },
    { id: 0, label: t('no') },
  ];
  const booleanStringOptions: OptionsItem<StringBoolean>[] = [
    { label: t('yes'), id: '1' },
    { label: t('no'), id: '0' },
  ];
  const booleanSpecOptions: OptionsItem<string>[] = [
    { label: t('yes'), id: t('yes') },
    { label: t('no'), id: t('no') },
  ];
  const confidentialityOptions: OptionsItem<StringBoolean>[] = [
    { id: '0', label: t('not_confidential') },
    { id: '1', label: t('confidential') },
  ];
  const currencyOptions: OptionsItem<string>[] = constants.localisation.currencyOptionsDropdown.map((option) => ({
    id: option.id,
    label: option.name,
  }));
  const monthQuarterOptions: OptionsItem<number>[] = [
    { label: t('january'), id: 1 },
    { label: t('february'), id: 2 },
    { label: t('march'), id: 3 },
    { label: t('april'), id: 4 },
    { label: t('may'), id: 5 },
    { label: t('june'), id: 6 },
    { label: t('july'), id: 7 },
    { label: t('august'), id: 8 },
    { label: t('september'), id: 9 },
    { label: t('october'), id: 10 },
    { label: t('november'), id: 11 },
    { label: t('december'), id: 12 },
    { label: t('q1'), id: 13 },
    { label: t('q2'), id: 14 },
    { label: t('q3'), id: 15 },
    { label: t('q4'), id: 16 },
  ];
  const viewingFeedbackRadioOptions: OptionsItem<ViewingOutcome>[] = [
    { id: ViewingOutcome.Positive, label: t('positive') },
    { id: ViewingOutcome.Neutral, label: t('neutral') },
    { id: ViewingOutcome.Negative, label: t('negative') },
  ];
  const viewingFeedbackMultiSelectOptions: OptionsItem<ViewingOutcome>[] = [
    { id: ViewingOutcome.Pending, label: t('pending_feedback') },
    { id: ViewingOutcome.None, label: t('no_feedback_given') },
    { id: ViewingOutcome.Positive, label: t('positive_feedback') },
    { id: ViewingOutcome.Neutral, label: t('neutral_feedback') },
    { id: ViewingOutcome.Negative, label: t('negative_feedback') },
    { id: ViewingOutcome.Cancelled, label: t('cancelled_hyphen_no_longer_interested') },
  ];

  return {
    // ---- Options ----

    acquisitionAdvertTypeOptions,
    acquisitionFittedSpaceOptions,
    booleanOptions,
    booleanStringOptions,
    booleanSpecOptions,
    buildingTypeOptions,
    classOfUseOptions,
    confidentialityOptions,
    currencyOptions,
    discountReasonOptions: stringObjectToOptions(constants.matchDiscountReasonOptions),
    disposalFittedSpaceOptions: stringObjectToOptions(constants.disposalFittedSpaceOptions),
    floorConsiderationsOptions: stringObjectToOptions(constants.floorConsiderations),
    interestScheduleShareRequirementOptions: stringObjectToOptions(constants.interestScheduleShareRequirementOptions),
    investmentProfileOptions,
    investmentStrategyOptions,
    investmentStructureOptions,
    leadQualityOptions: stringObjectToOptions(constants.leadQualityOptions),
    leaseTypeOptions,
    monthQuarterOptions,
    ownershipTypeOptions: stringObjectToNumberOptions(constants.availableSaleTypeOptions),
    preferredSectorOptions,
    preferredSourceOptions,
    requirementReasonOptions,
    requirementStatusOptions,
    requirementTenureTypeOptions: stringObjectToOptions(constants.requirementTenureTypeOptions),
    sectorOptions: sectorOptions,
    sourceOptions: allSourceOptions,
    tenancyStatusOptions: stringObjectToNumberOptions(constants.targetTenancyDetails),
    viewingFeedbackMultiSelectOptions,
    viewingFeedbackRadioOptions,

    // ---- Grouped options ----

    groupedBuildingTypesOptions: getGroupedOptions(
      constants.buildingTypeGroups,
      constants.buildingTypeGroupOrder,
      buildingTypeOptions
    ),
    groupedClassOfUseGroupOptions: getGroupedOptions(
      constants.classOfUseGroups,
      constants.classOfUseGroupOrder,
      classOfUseOptions
    ),
    groupedPreferredSectorOptions: optionsToFlatGroupOptions(preferredSectorOptions),
    groupedPreferredSourceOptions: optionsToFlatGroupOptions(preferredSourceOptions),
    groupedRequirementReasonOptions: optionsToFlatGroupOptions(requirementReasonOptions),
    groupedRequirementStatusOptions: optionsToFlatGroupOptions(requirementStatusOptions),
    groupedSectorOptions: optionsToFlatGroupOptions(sectorOptions),
    groupedInvestmentProfileOptions: optionsToFlatGroupOptions(investmentProfileOptions),
    groupedInvestmentStrategyOptions: optionsToFlatGroupOptions(investmentStrategyOptions),
    groupedInvestmentStructureOptions: optionsToFlatGroupOptions(investmentStructureOptions),
    groupedLeaseTypeOptions: optionsToFlatGroupOptions(leaseTypeOptions),
  };
};

export type UseCoreOptionsResult = ReturnType<typeof getCoreOptionsValue>;

const OptionsContext = createContext<UseCoreOptionsResult>({} as UseCoreOptionsResult);

export const useCoreOptions = () => {
  return useContext(OptionsContext);
};

export const CoreOptionsProvider: FC = ({ children }) => {
  const { config, constants } = useConfigAll();

  const result = useMemo(() => getCoreOptionsValue(constants, config.user), [constants, config.user]);

  return <OptionsContext.Provider value={result}>{children}</OptionsContext.Provider>;
};

export const useEmptyOption = <TOption extends OptionsItem = OptionsItem>(options: TOption[], emptyOption: TOption) => {
  return useMemo(() => [emptyOption, ...options], [options]);
};
