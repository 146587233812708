import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getBaseUrl } from '@core/base-url';
import { useConfigAll } from '@core/contexts/ConfigContext';
import { useStorageListeners } from '@core/hooks/localStorage';
import {
  PusherDuration,
  PusherKey,
  PusherKeyEnvUser,
  PusherKeySociety,
  PusherKeyUser,
  PusherNotificationKey,
  PusherNotificationPayload,
  PusherPayload,
} from '@shared/types/common/events';
import { Participant } from '@shared/types/common/participant';
import { getTransformedRoute } from '@shared/utils/route';

import { addOrUpdateToast, echoAuthorizer, getEventToast, getNotificationToast } from './PusherContext.utils';

// Expose Pusher on window, so Echo can access it
window.Pusher = Pusher;

type DismissedToastsValue = string[] | null;

export interface ToastItem {
  content: ReactNode;
  dismissTime: PusherDuration | null;
  key: string;
  replaceKey?: string;
  time: number;
}

const DISMISSED_TOASTS_KEY = 'dismissedToasts';

type PusherChannel = ReturnType<Echo<'pusher'>['channel']>;

export interface CustomPusherChannel extends PusherChannel {
  listen<TK extends PusherKey>(event: TK, callback: (payload: PusherPayload[TK]) => any): this;
  notification<TK extends PusherNotificationKey>(callback: (payload: PusherNotificationPayload[TK]) => any): this;
  stopListening<TK extends PusherKey>(event: TK, callback?: () => any): this;
}

export interface CustomPusherPrivateChannel extends CustomPusherChannel {
  whisper<TK extends PusherKey>(eventName: TK, data: PusherPayload[TK]): this;
}

export interface CustomPusherPresenceChannel extends CustomPusherPrivateChannel {
  here(callback: (participants: Participant[]) => void): this;
  joining(callback: (participant: Participant) => void): this;
  leaving(callback: (participant: Participant) => void): this;
}

export interface CustomEcho extends Echo<'pusher'> {
  channel(channel: string): CustomPusherPrivateChannel;
  encryptedPrivate(channel: string): CustomPusherPrivateChannel;
  join(channel: string): CustomPusherPresenceChannel;
  listen<TK extends PusherKey>(
    channel: string,
    event: TK,
    callback: (payload: PusherPayload[TK]) => any
  ): CustomPusherPrivateChannel;
  private(channel: string): CustomPusherPrivateChannel;
}

export interface PusherContextValue {
  echoInstance: CustomEcho;
  toasts: ToastItem[];
  addEventToast: (event: PusherKey, suppliedPayload: ValueOf<PusherPayload>) => void;
  addNotificationToast: (event: PusherNotificationKey, suppliedPayload: ValueOf<PusherNotificationPayload>) => void;
}

export const PusherContext = createContext<PusherContextValue>({} as PusherContextValue);

export const usePusher = () => {
  return useContext(PusherContext);
};

export const PusherProvider: FC = ({ children }) => {
  const { appId, config, constants } = useConfigAll();
  const history = useHistory();
  const storageListeners = useStorageListeners();

  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const dismissToast = (toastKey: string) => {
    // console.log('dismissToast:', toastKey);
    storageListeners.set<DismissedToastsValue>(DISMISSED_TOASTS_KEY, (prev) => {
      if (prev) {
        return [...prev, toastKey];
      } else {
        return [toastKey];
      }
    });
  };

  const removeToast = (toastKey: string) => {
    // console.log('removeToast:', toastKey);
    dismissToast(toastKey);
    setToasts((_prev) => _prev.filter((toast) => toast.key !== toastKey));
  };

  const echoInstance = useMemo(
    () =>
      new Echo({
        broadcaster: 'pusher',
        cluster: 'eu',
        encrypted: true,
        key: config.pusherKey,
        wsHost: getBaseUrl(),
        authorizer: echoAuthorizer,
      }) as CustomEcho,
    [config.pusherKey]
  );

  const isDismissed = (toast: ToastItem) => {
    return storageListeners.get<DismissedToastsValue>(DISMISSED_TOASTS_KEY)?.includes(toast.key);
  };

  const openToastLink = (url: string) => {
    const to = getTransformedRoute(config.appUrl, url);

    if (to.startsWith('http')) {
      window.open(to, '_blank');
    } else {
      history.push(to);
    }
  };

  const addEventToast = useCallback(
    (event: PusherKey, payload: ValueOf<PusherPayload>) => {
      // console.log(`Pusher event: ${event}`, suppliedPayload);
      const toast = getEventToast(event, payload, appId, config, removeToast, openToastLink);

      if (toast && !isDismissed(toast)) {
        // console.log(`addEventToast. adding: `, event, suppliedPayload);
        setToasts((_prev) => addOrUpdateToast(toast, _prev));
      }
    },
    [appId, config]
  );

  const addNotificationToast = useCallback(
    (event: PusherNotificationKey, payload: ValueOf<PusherNotificationPayload>) => {
      const toast = getNotificationToast(event, payload, appId, removeToast, openToastLink);

      if (toast && !isDismissed(toast)) {
        // console.log(`addNotificationToast. adding: `, event, suppliedPayload);
        setToasts((_prev) => addOrUpdateToast(toast, _prev));
      }
    },
    [appId]
  );

  useEffect(() => {
    // User event listeners
    const userChannel = echoInstance.private(`${constants.APP_ENV}-App.User.${config.user.id}`);
    userChannel.error((error: any) => console.error('User channel error:', error));
    userChannel.listen(PusherKeyEnvUser.ScheduleMatchAgentMessageCreated, (data) => {
      addEventToast(PusherKeyEnvUser.ScheduleMatchAgentMessageCreated, data);
    });

    // Society event listeners
    const societyChannels =
      config.user.societies?.map((society) => {
        return echoInstance.private('society-' + society.id + '-pulse');
      }) ?? [];
    societyChannels.forEach((channel, index) => {
      channel.error((error: any) =>
        console.error(`Society ${config.user?.societies?.[index]?.id} channel error:`, error)
      );

      if (config.user.notify_settings.acquisitions.where.desktop) {
        channel.listen(PusherKeySociety.AcquisitionPublishedToSociety, (data) => {
          addEventToast(PusherKeySociety.AcquisitionPublishedToSociety, data);
        });
      }

      if (config.user.notify_settings.disposals.where.desktop) {
        channel.listen(PusherKeySociety.LettingPublishedToSociety, (data) => {
          addEventToast(PusherKeySociety.LettingPublishedToSociety, data);
        });
      }
    });

    // Private user event listeners
    const privateUserChannel = echoInstance.private(`App.User.${config.user.id}`);
    privateUserChannel.error((error: any) => console.error('Private channel error:', error));
    privateUserChannel.notification((data) => addNotificationToast(data.type, data));
    privateUserChannel.listen(PusherKeyUser.BulkActionProgress, (data) => {
      addEventToast(PusherKeyUser.BulkActionProgress, data);
    });
    privateUserChannel.listen(PusherKeyUser.BulkActionCompleted, (data) => {
      addEventToast(PusherKeyUser.BulkActionCompleted, data);
    });

    return () => {
      // Clear user event listeners
      privateUserChannel.stopListening(PusherKeyUser.BulkActionProgress);
      privateUserChannel.stopListening(PusherKeyUser.BulkActionCompleted);
      userChannel.stopListening(PusherKeyEnvUser.ScheduleMatchAgentMessageCreated);

      // Clear society event listeners
      societyChannels.forEach((channel) => {
        if (config.user.notify_settings.acquisitions.where.desktop) {
          channel.stopListening(PusherKeySociety.AcquisitionPublishedToSociety);
        }
        if (config.user.notify_settings.disposals.where.desktop) {
          channel.stopListening(PusherKeySociety.LettingPublishedToSociety);
        }
      });
    };
  }, [constants, echoInstance, config.user]);

  // Ensure the toasts are cleared when the user dismisses a toast in another tab
  useEffect(() => {
    storageListeners.listen<DismissedToastsValue>(DISMISSED_TOASTS_KEY, (dismissedToasts) => {
      // console.log('dismissedToasts:', dismissedToasts);
      if (dismissedToasts) {
        setToasts((prev) => prev.filter((toast) => !dismissedToasts.includes(toast.key)));
      }
    });
  }, []);

  // Ensure the toasts are automatically dismissed
  useEffect(() => {
    const interval = setInterval(() => {
      setToasts((prev) => {
        const newToasts = prev.filter((toast) => {
          if (toast.dismissTime !== null && Date.now() - toast.time > toast.dismissTime) {
            dismissToast(toast.key);
            return false;
          }
          return true;
        });

        return newToasts.length !== prev.length ? newToasts : prev;
      });
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const contextValue = useMemo(() => {
    const result: PusherContextValue = {
      echoInstance: echoInstance,
      toasts: toasts,
      addEventToast: addEventToast,
      addNotificationToast: addNotificationToast,
    };
    return result;
  }, [addEventToast, addNotificationToast, echoInstance, toasts]);

  return <PusherContext.Provider value={contextValue}>{children}</PusherContext.Provider>;
};
